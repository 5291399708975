import debug from 'debug'

import PubSub from 'pubsub-js'

import jQuery from '~/client/assets/js/common/jquery'

import {
  handleSummaryChangeClick
} from '~/client/assets/js/behaviour/button'

const log = debug('behaviour:summary')

log('`behaviour` is awake')

export {
  default as handleSummaryPreviewContentLoaded
} from './preview'

export {
  default as handleSummaryOptionsContentLoaded
} from './options'

/**
 *  Summary Change
 */
export function handleSummaryChangeTopic (topic) {
  log('handleSummaryChangeTopic')

  PubSub.publish('summary:preview', 'hide')

  setTimeout(() => {
    PubSub.publish('summary:options', 'show')
  }, 330)
}

/**
 *  Summary Change (Content Loaded - Default Export)
 */
export default function handleContentLoaded () {
  log('handleContentLoaded')

  const $ = jQuery()

  $('#renderapp button.summary-change')
    .click(handleSummaryChangeClick)

  /**
   *  Summary Change
   */
  PubSub.subscribe('summary:change', handleSummaryChangeTopic)
}
