import debug from 'debug'

import PubSub from 'pubsub-js'

import jQuery from '~/client/assets/js/common/jquery'

import {
  queryMatchMediaExtraSmall,
  queryMatchMediaSmall,
  queryMatchMediaMedium,
  queryMatchMediaLarge,
  queryMatchMediaExtraLarge
} from '~/client/assets/js/common/device/match-media/breakpoints'

const log = debug('behaviour:breakpoints')

log('`behaviour` is awake')

export function handleMatchMediaExtraSmallChange ({ matches = false }) {
  log('handleMatchMediaExtraSmallChange')

  PubSub.publish('media:extra-small', matches)
}

export function handleMatchMediaSmallChange ({ matches = false }) {
  log('handleMatchMediaSmallChange')

  PubSub.publish('media:small', matches)
}

export function handleMatchMediaMediumChange ({ matches = false }) {
  log('handleMatchMediaMediumChange')

  PubSub.publish('media:medium', matches)
}

export function handleMatchMediaLargeChange ({ matches = false }) {
  log('handleMatchMediaLargeChange')

  PubSub.publish('media:large', matches)
}

export function handleMatchMediaExtraLargeChange ({ matches = false }) {
  log('handleMatchMediaExtraLarge')

  PubSub.publish('media:extra-large', matches)
}

export default function handleContentLoaded () {
  log('handleContentLoaded')

  queryMatchMediaExtraSmall()
    .addEventListener('change', handleMatchMediaExtraSmallChange)

  queryMatchMediaSmall()
    .addEventListener('change', handleMatchMediaSmallChange)

  queryMatchMediaMedium()
    .addEventListener('change', handleMatchMediaMediumChange)

  queryMatchMediaLarge()
    .addEventListener('change', handleMatchMediaLargeChange)

  queryMatchMediaExtraLarge()
    .addEventListener('change', handleMatchMediaExtraLargeChange)
}

{
  const $ = jQuery()

  $(handleContentLoaded)
}
