import debug from 'debug'

import PubSub from 'pubsub-js'

import jQuery from '~/client/assets/js/common/jquery'

import handleEventStopPropagation from '~/client/assets/js/behaviour/event/stop-propagation'

const log = debug('behaviour:menu-view')

log('`behaviour` is awake')

/**
 *  Initialise Top Level
 */
export function initialise () {
  log('initialise')

  const $ = jQuery()

  /*
   *  Get the menu item
   */
  const item = $('#menu li[data-key].active')

  /*
   *  Get the key
   */
  const key = item.data('key')

  /*
   *  Get the menu view
   */
  const view = $(`#menu-view > div[data-key=${key}]`)

  /*
   *  If the menu view has the `active` class, exit
   */
  if (view.hasClass('active')) return

  /*
   *  Otherwise ...
   */
  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  /*
   *  This sequence ensures that the menu view is put into its expected visual state as though it has
   *  the `active` class, but before it is given the `active` class, and without animating
   *
   *  Please excuse the pyramid of doom
   *
   *  Each subsequent call must execute after its predecessor has painted, so each call requests
   *  another frame to follow it
   */
  requestAnimationFrame(() => {
    log('requestAnimationFrame')

    /*
     *  Position the view without animating by adding the `initialise` class
     */
    view
      .addClass('initialise')

    /*
     *  Request another frame. The call will execute after this paint
     */
    requestAnimationFrame(() => {
      log('requestAnimationFrame')

      /*
       *  Add the `active` class. Since the view is in its expected visual state there is nothing to animate!
       */
      view
        .addClass('active')

      /*
       *  Request another frame. The call will execute after this paint
       */
      requestAnimationFrame(() => {
        log('requestAnimationFrame')

        /*
         *  Remove the `initialise` class. The view remains in its expected visual state
         */
        view
          .removeClass('initialise')

        /*
         *  Publish the key change
         */
        PubSub.publish('key:change', key)
      })
    })
  })
}

/**
 *  Menu View (Hide)
 */
export function handleHideDelegateClick () {
  log('handleHideDelegateClick')

  const $ = jQuery()
  const key = $(this).closest('[data-key]').data('key')

  if (key) PubSub.publish('key:hide', key)
}

/**
 *  Menu View (Accordion)
 */
export function handleAccordionDelegateClick () {
  log('handleAccordionDelegateClick')

  const $ = jQuery()
  const accordion = $(this).closest('.accordion')

  accordion.siblings().removeClass('expanded')
  accordion.toggleClass('expanded')

  PubSub.publish('accordion:change')
}

export function createTooltipTextContent (v) {
  /**
   *  log('createTooltipTextContent')
   */

  const $ = jQuery()

  return $('<span />')
    .addClass('text-content')
    .text(v)
}

export function createTooltip (v) {
  /**
   *  log('createTooltip')
   */

  const $ = jQuery()

  return $('<span />')
    .addClass('tooltip mezzo')
    .append(createTooltipTextContent(v))
}

export function removeEachSiblingTooltip (n, e) {
  /**
   *  log('removeEachSiblingTooltip')
   */

  const $ = jQuery()

  const {
    clearTimeout,
    setTimeout
  } = global

  const tooltip = $(e)

  tooltip.removeClass('active')

  const timeout = tooltip.data('timeout')
  if (timeout) clearTimeout(timeout)
  tooltip.data('timeout', setTimeout(removeTooltip(tooltip), 500))
}

export function removeTooltip (tooltip) {
  /**
   *  log('removeTooltip')
   */

  return function removeTooltip () {
    tooltip.remove()
  }
}

export function renderTooltip (tooltip) {
  /**
   *  log('renderTooltip')
   */

  return function renderTooltip () {
    tooltip
      .addClass('tooltip above active')
      .removeClass('mezzo')
  }
}

export function handleMouseEnter ({ target }) {
  /**
   *  log('handleMouseEnter')
   */

  if (this === target) {
    const $ = jQuery()

    const {
      clearTimeout,
      setTimeout
    } = global

    const container = $(this)

    const title = container.attr('title') || container.data('title')
    container.removeAttr('title')
    container.data('title', title)

    container.siblings()
      .find('.tooltip')
      .each(removeEachSiblingTooltip)

    const tooltip = $(container.find('.tooltip').get(0) || createTooltip(title))

    container.append(tooltip)

    const timeout = tooltip.data('timeout')
    if (timeout) clearTimeout(timeout)
    tooltip.data('timeout', setTimeout(renderTooltip(tooltip), 250))
  }
}

export function handleMouseLeave () {
  /**
   *  log('handleMouseLeave')
   */

  const $ = jQuery()
  const container = $(this)

  const title = container.data('title') || container.attr('title')
  container.removeData('title')
  container.attr('title', title)

  container
    .find('.tooltip')
    .each(removeEachSiblingTooltip)
}

/**
 *  Renderapp (Reset)
 */
export function handleResetTopic () {
  log('handleResetTopic')

  const $ = jQuery()

  $('#menu-view > div.active')
    .removeClass('active')

  $('#menu-view > div[data-key]:not(.active)').first()
    .addClass('active')
}

/**
 *  Menu View (Key Change)
 */
export function handleKeyChangeTopic (topic, param) {
  log('handleKeyChangeTopic')

  const $ = jQuery()

  $(`#menu-view > div.active:not([data-key=${param}])`)
    .removeClass('active')

  $(`#menu-view > div[data-key=${param}]:not(.active)`)
    .addClass('active')

  PubSub.publish('menu-view:change', param)
}

/**
 *  Menu View (Key Show)
 */
export function handleKeyShowTopic (topic, param) {
  log('handleKeyShowTopic')

  const $ = jQuery()

  $(`#menu-view > div[data-key=${param}]:not(.active)`)
    .addClass('active')

  PubSub.publish('menu-view:show', param)
}

/**
 *  Menu View (Key Hide)
 */
export function handleKeyHideTopic (topic, param) {
  log('handleKeyHideTopic')

  const $ = jQuery()

  $(`#menu-view > div[data-key=${param}].active`)
    .removeClass('active')

  PubSub.publish('menu-view:hide', param)
}

/**
 *  Menu View (Media Large)
 */
export function handleMediaLargeTopic (topic, param) {
  log('handleMediaLargeTopic')

  if (param) initialise()
}

/**
 *  Menu View (Media Extra Large)
 */
export function handleMediaExtraLargeTopic (topic, param) {
  log('handleMediaExtraLargeTopic')

  if (param) initialise()
}

/**
 *  Menu View (Content Loaded - Default Export)
 */
export default function handleContentLoaded () {
  log('handleContentLoaded')

  const $ = jQuery()

  /**
   *  Menu View (Hide)
   */
  $('#menu-view > div[data-key]')
    .delegate('h3', 'click', handleHideDelegateClick)

  $('#menu-view > div[data-key]')
    .delegate('.accordion h4', 'click', handleAccordionDelegateClick)

  $('#menu-view')
    .delegate('.bloblets label', 'mouseenter', handleMouseEnter)

  $('#menu-view')
    .delegate('.bloblets label', 'mouseleave', handleMouseLeave)

  /**
   *  Renderapp (Reset)
   */
  PubSub.subscribe('renderapp:reset', handleResetTopic)

  /**
   *  Menu View (Key Change)
   */
  PubSub.subscribe('key:change', handleKeyChangeTopic)

  /**
   *  Menu View (Key Show)
   */
  PubSub.subscribe('key:show', handleKeyShowTopic)

  /**
   *  Menu View (Key Hide)
   */
  PubSub.subscribe('key:hide', handleKeyHideTopic)

  /**
   *  Menu View (Media Large)
   */
  PubSub.subscribe('media:large', handleMediaLargeTopic)

  /**
   *  Menu View (Media Extra Large)
   */
  PubSub.subscribe('media:extra-large', handleMediaExtraLargeTopic)

  /**
   *  `handleEventStopPropagation`
   */
  $('#menu-view > div[data-key]')
    .delegate('h3 span', 'click', handleEventStopPropagation)

  /**
   *  Renderapp (Change)
   *
   *    - Raise a change event
   */
  $('#renderapp')
    .trigger('change')
}
