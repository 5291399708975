import debug from 'debug'

import PubSub from 'pubsub-js'

import jQuery from '~/client/assets/js/common/jquery'

import {
  handleSummaryPreviewShowClick,
  handleSummaryPreviewHideClick
} from '~/client/assets/js/behaviour/button'

const log = debug('behaviour:summary-preview')

log('`behaviour` is awake')

/**
 *  Summary Preview (Show)
 */
export function renderSummaryPreviewShow () {
  log('renderSummaryPreviewShow')

  const $ = jQuery()

  $('#summary-preview')
    .addClass('active')
}

/**
 *  Summary Preview (Hide)
 */
export function renderSummaryPreviewHide () {
  log('renderSummaryPreviewHide')

  const $ = jQuery()

  $('#summary-preview')
    .removeClass('active')
}

/**
 *  Summary Preview
 */
export function handleSummaryPreviewTopic (topic, param) {
  log('handleSummaryPreviewTopic')

  switch (param) {
    case 'show':
      return renderSummaryPreviewShow()
    case 'hide':
      return renderSummaryPreviewHide()
  }
}

/**
 *  Summary Preview (Content Loaded - Default Export)
 */
export default function handleContentLoaded () {
  log('handleContentLoaded')

  const $ = jQuery()

  $('#renderapp:not(.dealership) button.summary-show')
    .click(handleSummaryPreviewShowClick)

  $('#renderapp:not(.dealership) button.summary-hide')
    .click(handleSummaryPreviewHideClick)

  /**
   *  Summary Preview
   */
  PubSub.subscribe('summary:preview', handleSummaryPreviewTopic)
}
