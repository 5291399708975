import debug from 'debug'

import jQuery from '~/client/assets/js/common/jquery'

const log = debug('behaviour:button:to-top')

log('`behaviour` is awake')

export default function handleClick (event) {
  log('handleClick')

  const $ = jQuery()

  $(global).scrollTop(0)
}
