import debug from 'debug'

import PubSub from 'pubsub-js'

const log = debug('behaviour:button:summary-options')

log('`behaviour` is awake')

export function handleShowClick () {
  log('handleShowClick')

  PubSub.publish('summary:options', 'show')
}

export function handleHideClick () {
  log('handleHideClick')

  PubSub.publish('summary:options', 'hide')
}
