import debug from 'debug'

import PubSub from 'pubsub-js'

const log = debug('behaviour:button:reset')

log('`behaviour` is awake')

export default function handleClick (event) {
  log('handleClick')

  PubSub.publish('renderapp:reset')
}
