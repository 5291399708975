import debug from 'debug'

const log = debug('common:jquery')

export default function jQuery () {
  const {
    $ = function $ () {
      log('`jQuery` is not available')
    }
  } = global

  return $
}
