import debug from 'debug'

import PubSub from 'pubsub-js'

import jQuery from '~/client/assets/js/common/jquery'

import {
  isMatchMediaExtraSmall,
  isMatchMediaSmall,
  isMatchMediaMedium,
  isMatchMediaLarge,
  isMatchMediaExtraLarge
} from '~/client/assets/js/common/device/match-media/breakpoints'

const log = debug('behaviour:options')

log('`behaviour` is awake')

let resizeObserver

let timeout = null

export function startTimeout (f, n) {
  if (timeout) clearTimeout(timeout)
  timeout = setTimeout(function handleTimeout () {
    void (timeout = null)
    return f()
  }, n)
}

export function resetTimeout () {
  if (timeout) {
    clearTimeout(timeout)
    void (timeout = null)
  }
}

export function appendOptionsHeightFor (element) {
  /**
   *  log('appendOptionsHeightFor')
   */

  const $ = jQuery()

  const options = $('#options')
  const menuViewItem = $(element)

  options.css('height', menuViewItem.height())
}

export function appendOptionsHeight () {
  /**
   *  log('appendOptionsHeight')
   */

  const $ = jQuery()

  const options = $('#options')
  const menuViewItem = $('#menu-view > div[data-key].active .strap, #menu-view > div[data-key].active .stack')

  options.css('height', menuViewItem.height())
}

export function removeOptionsHeight () {
  /**
   *  log('removeOptionsHeight')
   */

  const $ = jQuery()

  const options = $('#options')

  options.removeAttr('style')
}

export function handleOptionsHeightFor (element) {
  log('handleOptionsHeightFor')

  if (isMatchMediaExtraSmall()) return appendOptionsHeightFor(element)

  if (isMatchMediaSmall()) return appendOptionsHeightFor(element)

  if (isMatchMediaMedium()) return appendOptionsHeightFor(element)

  if (isMatchMediaLarge()) return removeOptionsHeight()

  if (isMatchMediaExtraLarge()) return removeOptionsHeight()
}

export function handleOptionsHeight () {
  log('handleOptionsHeight')

  if (isMatchMediaExtraSmall()) return appendOptionsHeight()

  if (isMatchMediaSmall()) return appendOptionsHeight()

  if (isMatchMediaMedium()) return appendOptionsHeight()

  if (isMatchMediaLarge()) return removeOptionsHeight()

  if (isMatchMediaExtraLarge()) return removeOptionsHeight()
}

export function initialiseResizeObserver () {
  log('initialiseResizeObserver')

  const $ = jQuery()

  $('#menu-view > div[data-key].active .strap, #menu-view > div[data-key].active .stack')
    .each((i, element) => {
      resizeObserver.observe(element)
    })
}

export function disconnectResizeObserver () {
  log('disconnectResizeObserver')

  return (
    resizeObserver.disconnect()
  )
}

export function handleResizeObserver ([{ target }]) {
  /**
   *  log('handleResizeObserver')
   */

  startTimeout(() => {
    const {
      requestAnimationFrame = function requestAnimationFrame () {
        log('`requestAnimationFrame` is not available')
      }
    } = global

    requestAnimationFrame(() => {
      appendOptionsHeightFor(target)
    })
  }, 25)
}

/**
 *  Options (Menu View Change)
 */
export function handleMenuViewChangeTopic () {
  log('handleMenuViewChangeTopic')

  resetTimeout()

  if (isMatchMediaExtraSmall() || isMatchMediaSmall() || isMatchMediaMedium()) {
    initialiseResizeObserver()

    appendOptionsHeight()
  }

  if (isMatchMediaLarge() || isMatchMediaExtraLarge()) {
    disconnectResizeObserver()

    removeOptionsHeight()
  }
}

/**
 *  Options (Menu View Show)
 */
export function handleMenuViewShowTopic () {
  log('handleMenuViewShowTopic')

  resetTimeout()

  initialiseResizeObserver()

  appendOptionsHeight()
}

/**
 *  Options (Menu View Hide)
 */
export function handleMenuViewHideTopic () {
  log('handleMenuViewHideTopic')

  resetTimeout()

  disconnectResizeObserver()

  removeOptionsHeight()
}

/**
 *  Options (Accordion Change)
 */
export function handleAccordionChangeTopic () {
  log('handleAccordionChangeTopic')

  startTimeout(() => {
    const {
      requestAnimationFrame = function requestAnimationFrame () {
        log('`requestAnimationFrame` is not available')
      }
    } = global

    requestAnimationFrame(() => {
      const $ = jQuery()

      $('#menu-view > div[data-key].active .strap, #menu-view > div[data-key].active .stack')
        .each((i, element) => {
          handleOptionsHeightFor(element)
        })
    })
  }, 500)
}

/**
 *  Options (Media Extra Small)
 */
export function handleMediaExtraSmallTopic (topic, param) {
  log('handleMediaExtraSmallTopic')

  if (param) {
    resetTimeout()

    initialiseResizeObserver()

    appendOptionsHeight()
  }
}

/**
 *  Options (Media Small)
 */
export function handleMediaSmallTopic (topic, param) {
  log('handleMediaSmallTopic')

  if (param) {
    resetTimeout()

    initialiseResizeObserver()

    appendOptionsHeight()
  }
}

/**
 *  Options (Media Medium)
 */
export function handleMediaMediumTopic (topic, param) {
  log('handleMediaMediumTopic')

  if (param) {
    resetTimeout()

    initialiseResizeObserver()

    appendOptionsHeight()
  }
}

/**
 *  Options (Media Large)
 */
export function handleMediaLargeTopic (topic, param) {
  log('handleMediaLargeTopic')

  if (param) {
    resetTimeout()

    disconnectResizeObserver()

    removeOptionsHeight()
  }
}

/**
 *  Options (Media Extra Large)
 */
export function handleMediaExtraLargeTopic (topic, param) {
  log('handleMediaExtraLargeTopic')

  if (param) {
    resetTimeout()

    disconnectResizeObserver()

    removeOptionsHeight()
  }
}

/**
 *  Renderapp (Resize)
 */
export function handleResizeTopic () {
  log('handleResizeTopic')

  resetTimeout()

  handleOptionsHeight()
}

/**
 *  Renderapp (Change)
 */
export function handleChangeTopic () {
  log('handleChangeTopic')

  resetTimeout()

  handleOptionsHeight()
}

/**
 *  Options (Content Loaded - Default Export)
 */
export default function handleContentLoaded () {
  log('handleContentLoaded')

  resizeObserver = new ResizeObserver(handleResizeObserver)

  /**
   *  Options (Menu View Change)
   */
  PubSub.subscribe('menu-view:change', handleMenuViewChangeTopic)

  /**
   *  Options (Menu View Show)
   */
  PubSub.subscribe('menu-view:show', handleMenuViewShowTopic)

  /**
   *  Options (Menu View Hide)
   */
  PubSub.subscribe('menu-view:hide', handleMenuViewHideTopic)

  /**
   *  Options (Accordion Change)
   */
  PubSub.subscribe('accordion:change', handleAccordionChangeTopic)

  /**
   *  Options (Media Extra Small)
   */
  PubSub.subscribe('media:extra-small', handleMediaExtraSmallTopic)

  /**
   *  Options (Media Small)
   */
  PubSub.subscribe('media:small', handleMediaSmallTopic)

  /**
   *  Options (Media Medium)
   */
  PubSub.subscribe('media:medium', handleMediaMediumTopic)

  /**
   *  Options (Media Large)
   */
  PubSub.subscribe('media:large', handleMediaLargeTopic)

  /**
   *  Options (Media Extra Large)
   */
  PubSub.subscribe('media:extra-large', handleMediaExtraLargeTopic)

  /**
   *  Renderapp (Resize)
   */
  PubSub.subscribe('renderapp:resize', handleResizeTopic)

  /**
   *  Renderapp (Change)
   */
  PubSub.subscribe('renderapp:change', handleChangeTopic)

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  /**
   *  Defer
   */
  requestAnimationFrame(handleOptionsHeight)
}
