import debug from 'debug'

const log = debug('behaviour:event:stop-propagation')

log('`behaviour` is awake')

export default function handleEventStopPropagation (event) {
  log('handleEventStopPropagation')

  event.stopPropagation()
}
