import debug from 'debug'

import PubSub from 'pubsub-js'

import jQuery from '~/client/assets/js/common/jquery'

const log = debug('behaviour:menu-list')

log('`behaviour` is awake')

/**
 *  Menu List (Show)
 */
export function handleShowDelegateClick () {
  log('handleShowDelegateClick')

  const $ = jQuery()

  const key = $(this).data('key')

  if (key) PubSub.publish('key:show', key)
}

/**
 *  Renderapp (Reset)
 */
export function handleResetTopic () {
  log('handleResetTopic')

  const $ = jQuery()

  $('#menu-list > ul > li.active')
    .removeClass('active')

  $('#menu-list > ul > li[data-key]:not(.active)').first()
    .addClass('active')
}

/**
 *  Menu (Show)
 *
 *    - Remove the `active` class from the sub level if its key is `param`
 */
export function handleMenuShowTopic (topic, param) {
  log('handleMenuShowTopic')

  const $ = jQuery()

  /*
   *  Select top level of sub level if menu item's `key` is `param`
   */
  $(`#menu-list > ul > li[data-key=${param}]`)
    .removeClass('disabled')
}

/**
 *  Menu (Hide)
 *
 *    - Remove the `active` class from the sub level if its key is `param`
 */
export function handleMenuHideTopic (topic, param) {
  log('handleMenuHideTopic')

  const $ = jQuery()

  /*
   *  Select top level of sub level if menu item's `key` is `param`
   */
  $(`#menu-list > ul > li[data-key=${param}]`)
    .addClass('disabled')
}

/**
 *  Menu List (Content Loaded - Default Export)
 */
export default function handleContentLoaded () {
  log('handleContentLoaded')

  const $ = jQuery()

  /**
   *  Menu List (Show)
   */
  $('#menu-list > ul')
    .delegate('> li', 'click', handleShowDelegateClick)

  /**
   *  Renderapp (Reset)
   */
  PubSub.subscribe('renderapp:reset', handleResetTopic)

  /**
   *  Menu (Show)
   */
  PubSub.subscribe('menu:show', handleMenuShowTopic)

  /**
   *  Menu (Sub Level)
   */
  PubSub.subscribe('menu:hide', handleMenuHideTopic)
}
