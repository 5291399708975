import debug from 'debug'

import PubSub from 'pubsub-js'

import jQuery from '~/client/assets/js/common/jquery'

import './breakpoints'

import './post-message'

import {
  handleToTopClick,
  handleSummaryPreviewShowClick,
  handleSummaryOptionsShowClick
} from './button'

import handleMenuContentLoaded from './menu'
import handleOptionsContentLoaded from './options'
import handleMenuListContentLoaded from './menu-list'
import handleMenuViewContentLoaded from './menu-view'
import handleSummaryContentLoaded, {
  handleSummaryPreviewContentLoaded,
  handleSummaryOptionsContentLoaded
} from './summary'

const log = debug('behaviour')

log('`behaviour` is awake')

/*
 *  We're using two types of "click" handlers:
 *
 *    - Handlers attached to an element for clicks to it
 *
 *    - Handlers attached to an element for clicks to its descendants
 *
 *  The second type is a "delegate"
 *
 *  The "click" and "delegate click" handler functions don't look different from
 *  one another, and they don't behave very differently to each other, either
 *  (and certainly not in any way that they are being used here)
 *
 *    - https://api.jquery.com/click
 *
 *    - https://api.jquery.com/delegate
 *
 *  Do bear in mind that differences may emerge should you need to modify them
 */

export function reduceKey (accumulator, accordion) {
  /**
   *  log('reduceKey')
   */

  const $ = jQuery()
  const key = $(accordion).closest('[data-key]').data('key')

  return (
    accumulator.includes(key)
      ? accumulator
      : accumulator.concat(key)
  )
}

export function handleMenuViewAccordionGroup (n, e) {
  /**
   *  log('handleMenuViewAccordionGroup')
   */

  const $ = jQuery()
  const group = $(e)

  if (group.find('label input:not(:disabled)').length) {
    group.removeClass('disabled')
  } else {
    group.addClass('disabled')
  }
}

export function handleMenuViewAccordion (n, e) {
  /**
   *  log('handleMenuViewAccordion')
   */

  const $ = jQuery()
  const accordion = $(e)

  accordion
    .find('.group')
    .each(handleMenuViewAccordionGroup)

  if (accordion.find('label input:not(:disabled)').length) {
    accordion.removeClass('disabled')
  } else {
    accordion.addClass('disabled')
  }
}

export function handleMenuShow (key) {
  log('handleMenuShow')

  PubSub.publish('menu:show', key)
}

export function handleMenuHide (key) {
  log('handleMenuHide')

  PubSub.publish('menu:hide', key)
}

/**
 *  Renderapp (Resize)
 */
export function handleResize ({ target }) {
  log('handleResize')

  const $ = jQuery()
  const isRenderapp = $(target).is('#renderapp')

  if (isRenderapp) PubSub.publish('renderapp:resize')
}

/**
 *  Renderapp (Change)
 */
export function handleChange ({ target }) {
  log('handleChange')

  const $ = jQuery()
  const isRenderapp = $(target).is('#renderapp')

  if (isRenderapp) PubSub.publish('renderapp:change')
}

/**
 *  Renderapp (Change)
 */
export function handleChangeTopic (topic) {
  log('handleChangeTopic')

  const $ = jQuery()
  const menuView = $('#menu-view')

  const was = menuView.find('.accordion.disabled')

  const WAS = Array.from(was)
    .reduce(reduceKey, [])

  menuView
    .find('.accordion')
    .each(handleMenuViewAccordion)

  const now = menuView.find('.accordion.disabled')

  const NOW = Array.from(now)
    .reduce(reduceKey, [])

  WAS
    .filter((key) => !NOW.includes(key)).forEach(handleMenuShow)

  NOW
    .forEach(handleMenuHide)
}

/**
 *  Home (Content Loaded - Default Export)
 */
export default function handleContentLoaded () {
  log('handleContentLoaded')

  const $ = jQuery()

  $('#renderapp button#to-top')
    .click(handleToTopClick)

  /*
   *  View Summary
   */
  $('#renderapp:not(.dealership) #menu-list > ul > li.view-summary')
    .click(handleSummaryPreviewShowClick)

  /*
   *  View Summary
   */
  $('#renderapp.dealership #menu-list > ul > li.view-summary')
    .click(handleSummaryOptionsShowClick)

  /**
   *  Renderapp (Resize)
   */
  $('#renderapp')
    .resize(handleResize)

  /**
   *  Renderapp (Change)
   */
  $('#renderapp')
    .change(handleChange)

  /**
   *  Renderapp (Change)
   */
  PubSub.subscribe('renderapp:change', handleChangeTopic)
}

{
  const $ = jQuery()

  $(handleContentLoaded)
  $(handleOptionsContentLoaded)
  $(handleMenuContentLoaded)
  $(handleMenuListContentLoaded)
  $(handleMenuViewContentLoaded)
  $(handleSummaryContentLoaded)
  $(handleSummaryPreviewContentLoaded)
  $(handleSummaryOptionsContentLoaded)
}
