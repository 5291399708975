import debug from 'debug'

import PubSub from 'pubsub-js'

import jQuery from '~/client/assets/js/common/jquery'

import {
  handleSummaryOptionsShowClick,
  handleSummaryOptionsHideClick
} from '~/client/assets/js/behaviour/button'

const log = debug('behaviour:summary-options')

log('`behaviour` is awake')

/**
 *  Summary Options (Show)
 */
export function renderSummaryOptionsShow () {
  log('renderSummaryOptionsShow')

  const $ = jQuery()

  $('#summary-options')
    .addClass('active')
}

/**
 *  Summary Options (Hide)
 */
export function renderSummaryOptionsHide () {
  log('renderSummaryOptionsHide')

  const $ = jQuery()

  $('#summary-options')
    .removeClass('active')
}

/**
 *  Summary Options
 */
export function handleSummaryOptionsTopic (topic, param) {
  log('handleSummaryOptionsTopic')

  switch (param) {
    case 'show':
      return renderSummaryOptionsShow()
    case 'hide':
      return renderSummaryOptionsHide()
  }
}

/**
 *  Summary Options (Content Loaded - Default Export)
 */
export default function handleContentLoaded () {
  log('handleContentLoaded')

  const $ = jQuery()

  $('#renderapp.dealership button.summary-show, #renderapp button.summary-options-show')
    .click(handleSummaryOptionsShowClick)

  $('#renderapp.dealership button.summary-hide, #renderapp button.summary-options-hide')
    .click(handleSummaryOptionsHideClick)

  /**
   *  Summary Options
   */
  PubSub.subscribe('summary:options', handleSummaryOptionsTopic)
}
