import debug from 'debug'

import PubSub from 'pubsub-js'

import jQuery from '~/client/assets/js/common/jquery'

/**
 *  We expect there to be only one menu item with the `active` class so, whenever
 *  a menu item is clicked on, the click handler removes the class from any other
 *  menu item then adds it to the menu item clicked on
 *
 *  Menus use the delegate's selector to distinguish between menu items with
 *  and without the `active` class:
 *
 *    - Menu items with the `active` class do not match the delegate selector (they
 *      are exclued by `not(.active)`) so the delegate click handler is not called
 *      for clicks to those elements
 *
 *    - Menu items without the `active` class do match the delegate selector so the
 *      click handler is called for clicks to them
 *
 *  In other words:
 *
 *    - if a menu item has the class you can't click on it
 *
 *    - if a menu item doesn't have the class then you can
 *
 *    - if you click on a menu item without the class then then class is removed from
 *      any other menu items and added to the clicked menu item
 */

const log = debug('behaviour:menu')

log('`behaviour` is awake')

export function renderMenuSteps () {
  log('renderMenuSteps')

  const $ = jQuery()
  const menu = $('#menu > ul > li:visible .title h3')

  menu
    .each((n, e) => {
      $(e).text(`Step ${n + 1}`)
    })

  const i = menu.length

  $('#menu > .view-summary .title h3, #menu > .send-to-a-retailer .title h3')
    .each((n, e) => {
      $(e).text(`Step ${i + n + 1}`)
    })
}

/**
 *  Initialise (Top Level)
 */
export function initialiseTopLevel (menu) {
  log('initialiseTopLevel')

  /*
   *  Get all the top level menu items
   */
  const topLevel = menu.find('> ul > li[data-key]')

  /*
   *  If any menu item has the `active` class, exit
   */
  if (topLevel.hasClass('active')) return

  /*
   *  Get all the sub level menu items
   */
  const subLevel = menu.find('> ul > li:not([data-key]) > ul > li[data-key]')

  /*
   *  If any menu item has the `active` class, exit
   */
  if (subLevel.hasClass('active')) return

  /*
   *  Otherwise, get the first top level menu item and add the `active` class
   */
  topLevel.first()
    .addClass('active')
}

/**
 *  Initialise (Sub Level)
 */
export function initialiseSubLevel (topLevel) {
  log('initialiseSubLevel')

  /*
   *  Get all the sub level menu items
   */
  const subLevel = topLevel.find('> ul > li[data-key]')

  /*
   *  If any menu item has the `active` class, exit
   */
  if (subLevel.hasClass('active')) return

  /*
   *  Otherwise, get the first sub level menu item and add the `active` class
   */
  subLevel.first()
    .addClass('active')
}

/**
 *  Renderapp (Reset)
 */
export function handleResetTopic () {
  log('handleResetTopic')

  const $ = jQuery()

  $('#menu > ul > li.active')
    .removeClass('active')

  $('#menu > ul > li[data-key]:not(.active)').first()
    .addClass('active')
}

/**
 *  Menu (Top Level of Sub Level)
 */
export function handleTopLevelOfSubLevelDelegateClick () {
  log('handleTopLevelOfSubLevelDelegateClick')

  const $ = jQuery()

  const topLevel = $(this)
  const key = topLevel.data('key') || initialiseSubLevel(topLevel) || topLevel.find('> ul > li[data-key].active').data('key')
  topLevel.data('key', key)

  PubSub.publish('key:change', key)
}

/**
 *  Menu (Any Level)
 */
export function handleAnyLevelDelegateClick () {
  log('handleAnyLevelDelegateClick')

  const $ = jQuery()
  const key = $(this).data('key')

  if (key) PubSub.publish('key:change', key)
}

/**
 *  Menu (Top Level Key Change)
 *
 *    - Remove the `active` class from the top level if its key is not `param`
 *
 *    - Add the `active` class to the top level if its key is `param`
 */
export function handleTopLevelKeyChangeTopic (topic, param) {
  log('handleTopLevelKeyChangeTopic')

  const $ = jQuery()

  $(`#menu > ul > li.active:not([data-key=${param}])`)
    .removeClass('active')

  $(`#menu > ul > li[data-key=${param}]:not(.active)`)
    .addClass('active')
}

/**
 *  Menu (Top Level Key Show)
 *
 *    - Add the `active` class to the top level if its key is `param`
 */
export function handleTopLevelKeyShowTopic (topic, param) {
  log('handleTopLevelKeyShowTopic')

  const $ = jQuery()

  $(`#menu > ul > li[data-key=${param}]:not(.active)`)
    .addClass('active')
}

/**
 *  Menu (Top Level Key Hide)
 *
 *    - Remove the `active` class from the top level if its key is `param`
 */
export function handleTopLevelKeyHideTopic (topic, param) {
  log('handleTopLevelKeyHideTopic')

  const $ = jQuery()

  $(`#menu > ul > li[data-key=${param}].active`)
    .removeClass('active')
}

/**
 *  Menu (Top Level of Sub Level Key Change)
 *
 *    - Remove the `active` class from the top level if the sub level does not have the key
 *
 *    - Add the `active` class to the top level if the sub level has the key
 */
export function handleTopLevelOfSubLevelKeyChangeTopic (topic, param) {
  log('handleTopLevelOfSubLevelKeyChangeTopic')

  const $ = jQuery()

  /*
   *  Select top level of sub level if menu item's `key` is `param`
   */
  $('#menu > ul > li:not([data-key])').has(`> ul > li[data-key=${param}]`)
    .each((n, e) => {
      /*
       *  Select any top level if it has `active` class and has sub level. Filter to exclude this top level
       */
      $('#menu > ul > li.active:not([data-key])').has('> ul > li[data-key]').filter((N, E) => e !== E)
        .removeClass('active')

      /*
       *  Select top level of sub level if it does not have `active` class and the sub level menu item's `key` is `param`
       */
      $('#menu > ul > li:not([data-key]):not(.active)').has(`> ul > li[data-key=${param}]`)
        .addClass('active')
    })
}

/**
 *  Menu (Top Level of Sub Level Key Show)
 *
 *    - Add the `active` class to the top level if the sub level has the key
 */
export function handleTopLevelOfSubLevelKeyShowTopic (topic, param) {
  log('handleTopLevelOfSubLevelKeyShowTopic')

  const $ = jQuery()

  /*
   *  Select top level of sub level if the top level does not have `active` class and the sub level menu item's `key` is `param`
   */
  $('#menu > ul > li:not([data-key]):not(.active)').has(`> ul > li[data-key=${param}]`)
    .addClass('active')
}

/**
 *  Menu (Top Level of Sub Level Key Hide)
 *
 *    - Remove the `active` class from the top level if the sub level has the key
 */
export function handleTopLevelOfSubLevelKeyHideTopic (topic, param) {
  log('handleTopLevelOfSubLevelKeyHideTopic')

  const $ = jQuery()

  /*
   *  Select top level of sub level if the top level has `active` class and the sub level menu item's `key` is `param`
   */
  $('#menu > ul > li.active:not([data-key])').has(`> ul > li[data-key=${param}]`)
    .removeClass('active')
}

/**
 *  Menu (Sub Level Key Change)
 *
 *    - Remove the `active` class from the sub level if its key is `param`
 *
 *    - Add the `active` class to the sub level if its key is `param`
 */
export function handleSubLevelKeyChangeTopic (topic, param) {
  log('handleSubLevelKeyChangeTopic')

  const $ = jQuery()

  /*
   *  Select top level of sub level
   */
  $('#menu > ul > li:not([data-key])').has('> ul > li[data-key]')
    .each((n, e) => {
      const menu = $(e)

      /*
       *  Select sub level and remove `active` class if menu item has `active` class and menu item's `key` is not `param`
       */
      menu.find(`> ul > li.active:not([data-key=${param}])`)
        .removeClass('active')

      /*
       *  Select sub level and add `active` class if menu item's `key` is `param` and menu item does not have `active` class
       */
      menu.find(`> ul > li[data-key=${param}]:not(.active)`)
        .addClass('active')

      menu.has('li.active')
        .each(() => {
          /*
           *  Store `param` because sub level has `active` class
           */
          menu.data('key', param)
        })
    })
}

/**
 *  Menu (Sub Level Key Show)
 *
 *    - Add the `active` class to the sub level if its key is `param`
 */
export function handleSubLevelKeyShowTopic (topic, param) {
  log('handleSubLevelKeyShowTopic')

  const $ = jQuery()

  /*
   *  Select top level of sub level if menu item's `key` is `param`
   */
  $('#menu > ul > li:not([data-key])').has(`> ul > li[data-key=${param}]`)
    .each((n, e) => {
      const menu = $(e)

      menu
        /*
         *  Store `param`
         */
        .data('key', param)
        /*
         *  Select sub level and add `active` class if menu item's `key` is `param` and menu item does not have `active` class
         */
        .find(`> ul > li[data-key=${param}]:not(.active)`)
        .addClass('active')
    })
}

/**
 *  Menu (Sub Level Key Hide)
 *
 *    - Remove the `active` class from the sub level if its key is `param`
 */
export function handleSubLevelKeyHideTopic (topic, param) {
  log('handleSubLevelKeyHideTopic')

  const $ = jQuery()

  /*
   *  Select top level of sub level if menu item's `key` is `param`
   */
  $('#menu > ul > li:not([data-key])').has(`> ul > li[data-key=${param}]`)
    .each((n, e) => {
      const menu = $(e)

      menu
        /*
         *  Store `param`
         */
        .data('key', param)
        /*
         *  Select sub level and remove `active` class if menu item has `active` class and menu item's `key` is `param`
         */
        .find(`> ul > li.active[data-key=${param}]`)
        .removeClass('active')
    })
}

/**
 *  Menu (Show)
 *
 *    - Remove the `disabled` class from the sub level if its key is `param`
 */
export function handleMenuShowTopic (topic, param) {
  log('handleMenuShowTopic')

  const $ = jQuery()

  /*
   *  Select top level of sub level if menu item's `key` is `param`
   */
  $(`#menu > ul > li[data-key=${param}]`)
    .removeClass('disabled')

  renderMenuSteps()
}

/**
 *  Menu (Hide)
 *
 *    - Add the `disabled` class to the sub level if its key is `param`
 */
export function handleMenuHideTopic (topic, param) {
  log('handleMenuHideTopic')

  const $ = jQuery()

  /*
   *  Select top level of sub level if menu item's `key` is `param`
   */
  $(`#menu > ul > li[data-key=${param}]`)
    .addClass('disabled')

  renderMenuSteps()
}

/**
 *  Menu (Media Large)
 */
export function handleMediaLargeTopic (topic, active) {
  log('handleMediaLargeTopic')

  if (active) {
    const $ = jQuery()
    const menu = $('#menu')

    initialiseTopLevel(menu)
  }
}

/**
 *  Menu (Media Extra Large)
 */
export function handleMediaExtraLargeTopic (topic, active) {
  log('handleMediaExtraLargeTopic')

  if (active) {
    const $ = jQuery()
    const menu = $('#menu')

    initialiseTopLevel(menu)
  }
}

/**
 *  Menu (Content Loaded - Default Export)
 */
export default function handleContentLoaded () {
  log('handleContentLoaded')

  const $ = jQuery()

  /**
   *  Menu (Top Level of Sub Level)
   */
  $('#menu > ul')
    .delegate('> li:not([data-key]):not(.active):has(> ul > li[data-key])', 'click', handleTopLevelOfSubLevelDelegateClick)

  /**
   *  Menu (Any Level)
   */
  $('#menu ul')
    .delegate('> li[data-key]:not(.active)', 'click', handleAnyLevelDelegateClick)

  /**
   *  Renderapp (Reset)
   */
  PubSub.subscribe('renderapp:reset', handleResetTopic)

  /**
   *  Menu (Top Level)
   */
  PubSub.subscribe('key:change', handleTopLevelKeyChangeTopic)

  /**
   *  Menu (Top Level)
   */
  PubSub.subscribe('key:show', handleTopLevelKeyShowTopic)

  /**
   *  Menu (Top Level)
   */
  PubSub.subscribe('key:hide', handleTopLevelKeyHideTopic)

  /**
   *  Menu (Top Level of Sub Level)
   */
  PubSub.subscribe('key:change', handleTopLevelOfSubLevelKeyChangeTopic)

  /**
   *  Menu (Top Level of Sub Level)
   */
  PubSub.subscribe('key:show', handleTopLevelOfSubLevelKeyShowTopic)

  /**
   *  Menu (Top Level of Sub Level)
   */
  PubSub.subscribe('key:hide', handleTopLevelOfSubLevelKeyHideTopic)

  /**
   *  Menu (Sub Level)
   */
  PubSub.subscribe('key:change', handleSubLevelKeyChangeTopic)

  /**
   *  Menu (Sub Level)
   */
  PubSub.subscribe('key:show', handleSubLevelKeyShowTopic)

  /**
   *  Menu (Sub Level)
   */
  PubSub.subscribe('key:hide', handleSubLevelKeyHideTopic)

  /**
   *  Menu (Show)
   */
  PubSub.subscribe('menu:show', handleMenuShowTopic)

  /**
   *  Menu (Hide)
   */
  PubSub.subscribe('menu:hide', handleMenuHideTopic)

  /**
   *  Menu (Media Large)
   */
  PubSub.subscribe('media:large', handleMediaLargeTopic)

  /**
   *  Menu (Media Extra Large)
   */
  PubSub.subscribe('media:extra-large', handleMediaExtraLargeTopic)
}
