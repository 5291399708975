import debug from 'debug'

import PubSub from 'pubsub-js'

import jQuery from '~/client/assets/js/common/jquery'

import {
  isMatchMediaExtraSmall,
  isMatchMediaSmall,
  isMatchMediaMedium
} from '~/client/assets/js/common/device/match-media/breakpoints'

export function handlePostMessage () {
  log('handlePostMessage')

  const {
    parent = {
      postMessage () {
        log('`postMessage` is not available')
      }
    }
  } = global

  if (parent !== global) {
    const {
      scrollHeight: height
    } = document.getElementById('renderapp')

    parent.postMessage({ height }, '*')
  }
}

export function handleResizeObserver () {
  log('handleResizeObserver')

  if (isMatchMediaExtraSmall() || isMatchMediaSmall() || isMatchMediaMedium()) handlePostMessage()
}

export function handleResizeTopic () {
  log('handleResizeTopic')

  if (isMatchMediaExtraSmall() || isMatchMediaSmall() || isMatchMediaMedium()) handlePostMessage()
}

export function handleChangeTopic () {
  log('handleChangeTopic')

  if (isMatchMediaExtraSmall() || isMatchMediaSmall() || isMatchMediaMedium()) handlePostMessage()
}

const log = debug('behaviour:post-message')

log('`behaviour:post-message` is awake')

export default function handleContentLoaded () {
  log('handleContentLoaded')

  const {
    ResizeObserver,
    document
  } = global

  const resizeObserver = new ResizeObserver(handleResizeObserver)

  const element = document.getElementById('renderapp')

  resizeObserver.observe(element)

  /**
   *  Renderapp (Resize)
   */
  PubSub.subscribe('renderapp:resize', handleResizeTopic)

  /**
   *  Renderapp (Change)
   */
  PubSub.subscribe('renderapp:change', handleChangeTopic)
}

{
  const $ = jQuery()

  $(handleContentLoaded)
}
